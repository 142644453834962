
export default {
  name: "Footer",
  props: ["quisommesnous"],
  components: {
    LiensFooter: () => import("@/components/navigation/Footer/LiensFooter"),
    BottomFooter: () => import("@/components/navigation/Footer/BottomFooter"),
    QuiSommesNous: () => import("@/components/navigation/Footer/QuiSommesNous"),
    MobileNavbar: () => import("@/components/navigation/Footer/MobileNavbar"),
  },
  computed: {
    isObfuscated() {
      return this.$route.path != "/";
    },
  },
};
